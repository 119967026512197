import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applied-jobs',
  templateUrl: './applied-jobs.component.html',
})
export class AppliedJobsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
