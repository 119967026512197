import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browse-candidate',
  templateUrl: './browse-candidate.component.html',
  styleUrls: ['./browse-candidate.component.scss'],
})
export class BrowseCandidateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
